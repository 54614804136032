<template>
	<div class="detail-warp">
		<div class="goods-detail">
			<div class="preview-wrap">
				<div class="video-player-wrap" :class="{ show: switchMedia == 'video' }"
					v-if="goodsSkuDetail.video_url != ''">
					<video-player v-if="goodsSkuDetail.video_url != ''" ref="videoPlayer" :playsinline="true"
						:options="playerOptions" @play="onPlayerPlay($event)" @pause="onPlayerPause($event)"
						@ended="onPlayerEnded($event)" @waiting="onPlayerWaiting($event)" @playing="onPlayerPlaying($event)"
						@loadeddata="onPlayerLoadeddata($event)" @timeupdate="onPlayerTimeupdate($event)"
						@canplay="onPlayerCanplay($event)" @canplaythrough="onPlayerCanplaythrough($event)"
						@statechanged="playerStateChanged($event)" @ready="playerReadied"></video-player>

					<div class="media-mode" v-if="goodsSkuDetail.video_url != ''">
						<span :class="{ 'ns-bg-color': switchMedia == 'video' }" @click="switchMedia = 'video'">视频</span>
						<span :class="{ 'ns-bg-color': switchMedia == 'img' }" @click="switchMedia = 'img'">图片</span>
					</div>
				</div>
				<div class="magnifier-wrap">
					<pic-zoom ref="PicZoom" :url="$img(picZoomUrl)" :scale="2"></pic-zoom>
				</div>

				<div class="spec-items">
					<span class="left-btn iconfont iconarrow-left-copy" :class="{ move: moveThumbLeft }"
						@click="changeThumbImg('prev')"></span>
					<span class="right-btn iconfont iconarrow-right" :class="{ move: moveThumbRight }"
						@click="changeThumbImg('next')"></span>
					<ul :style="{ left: 30 + thumbPosition + 'px' }">
						<!-- 商品缩率图 -->
						<li v-for="(item, index) in goodsSkuDetail.sku_images" :key="index" @mousemove="picZoomUrl = item"
							:class="{ selected: picZoomUrl == item }">
							<img :src="$img(item, { size: 'small' })" @error="imageErrorSpec(index)" />
						</li>
					</ul>
				</div>
				<!-- 
				<div class="share-collect">
					<div @click="editCollection">
						<i class="iconfont" :class="whetherCollection == 1 ? 'iconlikefill ns-text-color' : 'iconlike'"></i>
						<span data-collects="0" class="focus-text">关注商品（{{ goodsSkuDetail.collect_num }}）</span>
					</div>
					<div @click="service_link">
						<i class="iconfont iconzhanghao"></i>
						<span data-collects="0">联系客服</span>
					</div>

				</div> -->
			</div>

			<!-- 商品信息 -->
			<div class="basic-info-wrap" v-loading="loading">
				<h1>{{ goodsSkuDetail.sku_name }}</h1>
				<p class="desc ns-text-color" v-if="goodsSkuDetail.introduction">{{ goodsSkuDetail.introduction }}</p>

				<div class="discount-banner ns-bg-color" v-if="groupbuyTimeMachine.currentTime">
					<div class="activity-name">
						<i class="discount-icon iconfont iconicon_naozhong"></i>
						<span>团购</span>
					</div>
					<div class="surplus-time">
						<span>{{ groupbuyText }}</span>
						<count-down class="count-down" v-on:start_callback="countDownS_cb()"
							v-on:end_callback="countDownE_cb()" :currentTime="groupbuyTimeMachine.currentTime"
							:startTime="groupbuyTimeMachine.startTime" :endTime="groupbuyTimeMachine.endTime" :dayTxt="'天'"
							:hourTxt="'小时'" :minutesTxt="'分钟'" :secondsTxt="'秒'"></count-down>
					</div>
				</div>

				<div class="item-block">
					<div class="promotion-price">
						<dl class="item-line">
							<dt class="ns-text-color-gray">团购价</dt>
							<dd>
								<em class="yuan ns-text-color">¥</em>
								<span class="price ns-text-color">{{ goodsSkuDetail.groupbuy_price }}</span>
							</dd>
						</dl>
						<dl class="item-line">
							<dt class="ns-text-color-gray">市场价</dt>
							<dd>
								<em class="market-yuan">¥</em>
								<span class="market-price">{{ goodsSkuDetail.price }}</span>
							</dd>
						</dl>

						<div class="statistical">
							<ul>
								<!-- <li>
									<p>累计评价</p>
									<span>{{ goodsSkuDetail.evaluate }}</span>
								</li> -->
								<li>
									<span>累计销量</span>
									<span style="color:red">&nbsp;&nbsp;{{ goodsSkuDetail.sale_num }}&nbsp;&nbsp;</span>
									<span>{{ goodsSkuDetail.unit }}</span>
								</li>
							</ul>
						</div>

						<!-- <dl class="item-line" v-if="goodsSkuDetail.is_virtual == 0">
							<dt>运费</dt>
							<dd>
								<i class="i-activity-flag ns-text-color ns-border-color"
									v-if="goodsSkuDetail.is_free_shipping">快递免邮</i>
								<i class="i-activity-flag ns-text-color ns-border-color" v-else>快递不免邮</i>
							</dd>
						</dl> -->
					</div>
				</div>
				<dl class="item-line service">
					<dt>促销</dt>
					<dd style="position: relative;line-height: 2;margin-top: 5px;">
						<p v-if="freeshipping">免运费（满{{ freeshipping.price }}元包邮）</p>
						<p @click="saleShow = true" style="cursor: pointer;">共{{ freeshipping?1:0 }}项，展开查看 <i
								class="el-icon-arrow-down"></i></p>

						<div v-if="saleShow" class="sale-rule-content">
							<div class="title"><span class="sale-name">满即送</span>共<strong>{{ freeshipping?1:0
							}}</strong>项，促销活动规则<a @click="saleShow = false" style="cursor: pointer;"
									wttype="hide-rule">关闭</a></div>
							<div class="content">
								<div class="mjs-remark">活动多</div>
							</div>
						</div>

					</dd>
				</dl>
				<dl class="item-line delivery" v-if="goodsSkuDetail.is_virtual == 0">
					<dt>配送至</dt>
					<dd>
						<div class="region-selected ns-border-color-gray">
							<span>
								<template v-if="selectedAddress['level_1']">
									<template v-for="item in selectedAddress">
										{{ item.name }}
									</template>
								</template>
								<template v-else>
									请选择配送地址
								</template>
							</span>
							<i class="el-icon-arrow-down"></i>
						</div>

						<div class="region-list ns-border-color-gray" :class="{ hide: hideRegion }">
							<ul class="nav-tabs">
								<li :class="{ active: currTabAddres == 'province' }" @click="currTabAddres = 'province'">
									<div>
										<span>{{ selectedAddress['level_1'] ? selectedAddress['level_1'].name : '请选择省'
										}}</span>
										<i class="el-icon-arrow-down"></i>
									</div>
								</li>
								<li :class="{ active: currTabAddres == 'city' }" @click="currTabAddres = 'city'">
									<div>
										<span>{{ selectedAddress['level_2'] ? selectedAddress['level_2'].name : '请选择市'
										}}</span>
										<i class="el-icon-arrow-down"></i>
									</div>
								</li>
								<li :class="{ active: currTabAddres == 'district' }" @click="currTabAddres = 'district'">
									<div>
										<span>{{ selectedAddress['level_3'] ? selectedAddress['level_3'].name : '请选择区/县'
										}}</span>
										<i class="el-icon-arrow-down"></i>
									</div>
								</li>
							</ul>
							<div class="tab-content">
								<div class="tab-pane" :class="{ active: currTabAddres == 'province' }">
									<ul class="province">
										<li v-for="(item, index) in provinceArr" :key="index"
											:class="{ selected: selectedAddress['level_' + item.level] && selectedAddress['level_' + item.level].id == item.id }">
											<span @click="getAddress('city', item)">{{ item.name }}</span>
										</li>
									</ul>
								</div>
								<div class="tab-pane" :class="{ active: currTabAddres == 'city' }">
									<ul class="city">
										<li v-for="(item, index) in cityArr" :key="index"
											:class="{ selected: selectedAddress['level_' + item.level] && selectedAddress['level_' + item.level].id == item.id }">
											<span @click="getAddress('district', item)">{{ item.name }}</span>
										</li>
									</ul>
								</div>
								<div class="tab-pane" :class="{ active: currTabAddres == 'district' }">
									<ul class="district">
										<li v-for="(item, index) in districtArr" :key="index"
											:class="{ selected: selectedAddress['level_' + item.level] && selectedAddress['level_' + item.level].id == item.id }">
											<span @click="getAddress('community', item)">{{ item.name }}</span>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</dd>
				</dl>
				<dl class="item-line service">
					<dt>服务</dt>
					<dd>
						<span>
							由
							<router-link :to="'/shop-' + shopInfo.site_id" class="ns-text-color">{{ shopInfo.site_name
							}}</router-link>
							发货并提供售后服务
						</span>
					</dd>
				</dl>
				<hr class="divider" />
				<div class="sku-list" v-if="goodsSkuDetail.goods_spec_format">
					<dl class="item-line" v-for="(item, index) in goodsSkuDetail.goods_spec_format" :key="index">
						<dt>{{ item.spec_name }}</dt>
						<dd>
							<ul>
								<li v-for="(item_value, index_value) in item.value" :key="index_value">
									<div :class="{
										'selected ns-border-color': item_value['selected'] || skuId == item_value.sku_id,
										disabled: item_value['disabled'] || (!item_value['selected'] && specDisabled)
									}" @click="changeSpec(item_value.sku_id, item_value.spec_id)">
										<img v-if="item_value.image" :src="$img(item_value.image, { size: 'small' })" />
										<span>{{ item_value.spec_value_name }}</span>
										<i class="iconfont iconduigou1 ns-text-color"></i>
									</div>
								</li>
							</ul>
						</dd>
					</dl>
				</div>

				<div class="buy-number">
					<dl class="item-line">
						<dt>数量</dt>
						<dd>
							<div class="num-wrap">
								<el-input v-model="number" placeholder="0" @input="keyInput(false)" @blur="blur"></el-input>
								<div class="operation">
									<span class="increase el-icon-caret-top" @click="changeNum('+')"></span>
									<span class="decrease el-icon-caret-bottom" @click="changeNum('-')"></span>
								</div>
							</div>
							<span class="unit">{{ goodsSkuDetail.unit }}</span>
							<span class="inventory">库存{{ goodsSkuDetail.stock }}{{ goodsSkuDetail.unit }}</span>
							<!-- 限购 -->
							<em v-if="limitNumber > 0">({{ limitNumber }}{{ goodsSkuDetail.unit }}起购)</em>
						</dd>
					</dl>
				</div>

				<dl class="item-line buy-btn">
					<dt></dt>
					<dd>
						<template v-if="goodsSkuDetail.goods_state == 1 && goodsSkuDetail.verify_state == 1">
							<el-button type="primary" plain @click="buyNow">立即抢购</el-button>
						</template>
						<template v-else>
							<el-button type="info" plain disabled>该商品已下架</el-button>
						</template>
						<div href="javascript:;" class="go-phone">
							<img src="@/assets/images/goods/qrcode.png" />
							<div class="qrcode-wrap"><img :src="qrcode" alt="二维码图片" /></div>
						</div>
					</dd>
				</dl>

				<dl class="item-line merchant-service" v-show="shopInfo.shop_baozh == 1 ||
					shopInfo.shop_qtian == 1 ||
					shopInfo.shop_zhping == 1 ||
					shopInfo.shop_erxiaoshi == 1 ||
					shopInfo.shop_tuihuo == 1 ||
					shopInfo.shop_shiyong == 1 ||
					shopInfo.shop_shiti == 1 ||
					shopInfo.shop_xiaoxie == 1
					">
					<dt>商家服务</dt>
					<!-- <div>
						<dd class="service-li" v-if="shopInfo.shop_baozh == 1">
							<i class="el-icon-success"></i>
							<span class="ns-text-color-gray" title="保证服务">保证服务</span>
						</dd>
						<dd class="service-li" v-if="shopInfo.shop_qtian == 1">
							<i class="el-icon-success"></i>
							<span class="ns-text-color-gray"
								title="满足7天无理由退换货申请的前提下，包邮商品需要买家承担退货邮费，非包邮商品需要买家承担发货和退货邮费">7天退换</span>
						</dd>
						<dd class="service-li" v-if="shopInfo.shop_zhping == 1">
							<i class="el-icon-success"></i>
							<span class="ns-text-color-gray" title="商品支持正品保障服务">正品保障</span>
						</dd>
						<dd class="service-li" v-if="shopInfo.shop_erxiaoshi == 1">
							<i class="el-icon-success"></i>
							<span class="ns-text-color-gray" title="付款后2小时内发货">两小时发货</span>
						</dd>
						<dd class="service-li" v-if="shopInfo.shop_tuihuo == 1">
							<i class="el-icon-success"></i>
							<span class="ns-text-color-gray" title="退货承诺">退货承诺</span>
						</dd>
						<dd class="service-li" v-if="shopInfo.shop_shiyong == 1">
							<i class="el-icon-success"></i>
							<span class="ns-text-color-gray" title="试用中心">试用中心</span>
						</dd>
						<dd class="service-li" v-if="shopInfo.shop_shiti == 1">
							<i class="el-icon-success"></i>
							<span class="ns-text-color-gray" title="实体验证">实体验证</span>
						</dd>
						<dd class="service-li" v-if="shopInfo.shop_xiaoxie == 1">
							<i class="el-icon-success"></i>
							<span class="ns-text-color-gray" title="如有商品质量问题、描述不符或未收到货等，您有权申请退款或退货，来回邮费由卖家承担">消协保证</span>
						</dd>
					</div> -->
					<div>
						<dd v-if="goodsSkuDetail.goods_tuangou == 1">
							<i class="el-icon-success"></i>
							<span class="ns-text-color-gray" title="">支持团购</span>
						</dd>
						<dd v-if="goodsSkuDetail.goods_qtian == 1">
							<i class="el-icon-success"></i>
							<span class="ns-text-color-gray"
								title="满足7天无理由退换货申请的前提下，包邮商品需要买家承担退货邮费，非包邮商品需要买家承担发货和退货邮费">7天退换</span>
						</dd>
						<dd v-if="goodsSkuDetail.goods_fupin == 1">
							<i class="el-icon-success"></i>
							<span class="ns-text-color-gray" title="">扶贫宝贝</span>
						</dd>
						<dd v-if="goodsSkuDetail.goods_yanxuan == 1">
							<i class="el-icon-success"></i>
							<span class="ns-text-color-gray" title="">纳福严选</span>
						</dd>
						<dd v-if="goodsSkuDetail.goods_yuyue == 1">
							<i class="el-icon-success"></i>
							<span class="ns-text-color-gray" title="">预约送货</span>
						</dd>
						<dd v-if="goodsSkuDetail.goods_quanqiu == 1">
							<i class="el-icon-success"></i>
							<span class="ns-text-color-gray" title="">全球精品</span>
						</dd>
						<dd v-if="goodsSkuDetail.goods_ziti == 1">
							<i class="el-icon-success"></i>
							<span class="ns-text-color-gray" title="">门店自提</span>
						</dd>
						<dd v-if="!goodsSkuDetail.goods_qtian">
							<i class="el-icon-remove-outline" style="color:#000"></i>
							<span class="ns-text-color-gray" title="">不支持七天无理由退货</span>
						</dd>
					</div>
				</dl>
			</div>

			<!-- 店铺信息 -->
			<div class="shop-wrap">
				<goods-history />
				<!-- <div class="head-wrap">
				<div class="img-wrap">
					<img class="img-responsive center-block" :src="shopInfo.avatar ? $img(shopInfo.avatar) : $img(defaultShopImage)"
					 @error="shopInfo.avatar = defaultShopImage" :alt="shopInfo.site_name" />
				</div>
				<h5>
					<span class="site-name">{{ shopInfo.site_name }}</span>
					<el-tag class="tag" size="small" v-if="shopInfo.is_own == 1">自营</el-tag>
				</h5>
			</div>
			<div class="info-wrap">
				<dl>
					<dt class="site-score">店铺评分</dt>
					<dd>
						<el-rate v-model="score" disabled></el-rate>
					</dd>
					<dt>商品描述：</dt>
					<dd>
						<span>{{ shopInfo.shop_desccredit }}</span>
						分
					</dd>
					<dt>卖家服务：</dt>
					<dd>
						<span>{{ shopInfo.shop_servicecredit }}</span>
						分
					</dd>
					<dt>发货速度：</dt>
					<dd>
						<span>{{ shopInfo.shop_deliverycredit }}</span>
						分
					</dd>
				</dl>
			</div>
			<div class="info-wrap" v-if="shopInfo.telephone">
				<dl>
					<dt>联系电话：</dt>
					<dd>{{ shopInfo.telephone }}</dd>
				</dl>
			</div>
			<div class="operation">
				<el-button class="btn btn-default" size="medium" @click="$router.pushToTab('/shop-' + shopInfo.site_id)">进店逛逛</el-button>
				<el-button size="medium" @click="follow" v-if="hasFollow">取消关注</el-button>
				<el-button size="medium" @click="follow" v-else>关注店铺</el-button>
			</div> -->
			</div>

			<!--联系客服弹窗-->
			<servicerMessage ref="servicerMessage" class="kefu"
				:shop="{ shop_id: shopInfo.site_id, logo: shopInfo.logo, shop_name: shopInfo.site_name }"></servicerMessage>
		</div>
		<div class="detail-message">
			<div class="detail-wrap">
				<div class="goods-recommended">
					<!-- <goods-recommend /> -->
					<customer-service-center :shop-info="shopInfo" :service-link="service_link" />
					<goods-recommend class="clearfix" style="background: white;margin-top: 20px;float: left;border:0;" />
				</div>

				<el-tabs class="goods-tab" v-model="tabName" type="card" @tab-click="tabChange">
					<el-tab-pane label="商品详情" name="detail" style="padding: 0 114px">
						<div v-html="goodsSkuDetail.goods_content"></div>
					</el-tab-pane>
					<el-tab-pane label="售后保障" name="after_sale" class="after-sale" style="padding: 0 20px">
						<div v-html="service"></div>
					</el-tab-pane>
				</el-tabs>
			</div>
			<div style="clear: both;"></div>
		</div>

	</div>
</template>

<script>
import PicZoom from 'vue-piczoom';
import detail from './detail_groupbuy.js';
import GoodsRecommend from '@/components/GoodsRecommend';
import servicerMessage from "@/components/message/servicerMessage";
import GoodsHistory from '@/components/GoodsHistory';
import CustomerServiceCenter from "@/components/CustomerServiceCenter"
export default {
	name: 'groupbuy_detail',
	components: {
		PicZoom,
		GoodsRecommend,
		servicerMessage,
		GoodsHistory,
		CustomerServiceCenter
	},
	mixins: [detail]
};
</script>
<style lang="scss">@import './detail_groupbuy.scss';</style>
